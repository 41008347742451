export default {

  isAuthenticated: state => !!state.authToken,
  getAuthStatus: state => state.authStatus,
  getAuthScopes: state => state.authScopes,
  getAuthUser : state => state.authUser,

  getTimeaccount : state => state.timeaccount,

  getErrors : state => state.errors,

}
