<template>
    <div>
      <v-container>
        <h4>Apps</h4>
        <v-row>
          <v-col cols="6" v-for="app in apps" :key="app._id">
            <div
              @click="goto(app)"
              class="text-center clickable pt-3 pb-3"
            >
            <v-icon >{{ app.icon }}</v-icon><br/>
            <span>{{ app.name }}</span>
      
            </div>
            
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'AppCatalogue',
  data(){
    return {
      apps : []
    }
  },
  created () {
    this.initialize()
  },
  computed: {
  },
  methods: {
    initialize() {
      axios(
        { 
          url: 'http://api.horizonx.cloud/apps/apps',
          method: 'GET',
        })
        .then(resp => {
          this.apps = resp.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    goto(app){
      console.log("goto " + app.route)
      //window.location = "http://apps.horizonx.cloud/" + route
      //window.open("http://apps.horizonx.cloud/" + route)
      window.open("/" + app.route)
    }

  }

}
</script>

<style lang="scss">
.clickable {}
.clickable:hover {cursor: pointer; background-color: #e0e0e0}

</style>
