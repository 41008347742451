export default {  

  authToken: localStorage.getItem('user-token') || '',
  authStatus: '',
  authName: '',
  authTenantName: '',
  authScopes: [],
  authUser : null,

  apps: [],

  errors: [],
  

}
