import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import axios from 'axios'

Vue.use(Vuex)

const authToken = localStorage.getItem('user-token')
if (authToken) {
  axios.defaults.headers.common['x-access-token'] = authToken  
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
