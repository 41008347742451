<template>
  <v-app sticky-container>

    <v-navigation-drawer v-model="drawerRight" app clipped right v-if="userIsAuthenticated">
      <v-list dense>
        <v-list-item v-if="!userIsAuthenticated" to="/login" @click="drawerRight = false">
          <v-list-item-icon>
            <v-icon>power_settings_new</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="userIsAuthenticated" @click="logout();drawerRight = false">
          <v-list-item-icon>
            <v-icon>power_settings_new</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-container v-if="userIsAuthenticated && userData">

          <span><b>{{userData.identity.name}}</b></span><br/>
          <span class="caption">{{userData.tenant.speakingName}}</span>

      </v-container>
    </v-navigation-drawer>  

    <v-navigation-drawer v-model="drawerRightAppCatalogue" app clipped right v-if="userIsAuthenticated">
      <app-catalogue></app-catalogue>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="drawer" app
      :clipped="true"      
       v-if="userIsAuthenticated"
    >
      <v-list dense>

        <v-list-item to="/" link @click="drawer = false">
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Start</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar app
      color="white"
      clipped-left
      clipped-right
       v-if="userIsAuthenticated"
    >

      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title></v-toolbar-title>

      
      <v-spacer />      
      <v-btn icon @click.stop="drawerRightAppCatalogue = !drawerRightAppCatalogue">
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-btn icon @click.stop="drawerRight = !drawerRight">
        <v-icon>person</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="grey lighten-5">      
        <router-view></router-view>
    </v-main>

    <!--<component-footer></component-footer>-->
    <!--<custom-bottom-bar></custom-bottom-bar>-->

  </v-app>
</template>

<script>

import ComponentFooter from './components/ComponentFooter.vue'
import CustomBottomBar from './components/CustomBottomBar.vue'
import AppCatalogue from './components/AppCatalogue.vue'
import axios from 'axios'

export default {
  data () {
    return {
      drawer: false,
      drawerRight: false,
      drawerRightAppCatalogue: false
    }
  },
  created () {    
    axios.interceptors.response.use(undefined, (err) => {
      return new Promise((resolve, reject) => {
        if(err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch(AUTH_LOGOUT)          
        }
        throw err
      })
    })    
    if(this.$store.getters.isAuthenticated) {this.$store.dispatch('AUTH_SETUSER')}    
  },
  computed: {    
    userIsAuthenticated () {
      if(this.$store.getters.isAuthenticated) { return 1 } else { return 0 }
    },
    userData(){
      return this.$store.getters.getAuthUser    
    }
  },
  methods : {
    logout() {
      this.$store.dispatch('AUTH_LOGOUT')
      .then(() => {
        this.$router.push('/login')
      })
    }
  },
  components: {
    'component-footer': ComponentFooter,
    'custom-bottom-bar': CustomBottomBar,
    'app-catalogue': AppCatalogue,
  }
}
</script>