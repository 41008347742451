<template>
      <div class="loginbackground">
        <v-container fluid style="height: 100vh;" class="px-0 py-0">
          <v-layout fill-height>

            
              <v-row
                align="center"
                justify="center"
                class="mx-0 py-0"
              >
                <v-col>
                  <form class="login-card" @submit.prevent="login" style="text-align: center">
                  <v-card class="elevation-12" max-width="800px">
                    <v-toolbar color="blue-grey" dark flat>
                      <v-toolbar-title>Login</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-form>
                        <v-text-field
                          label="Mandant"
                          name="tenantname"
                          v-model="tenantname"
                          prepend-icon="account"
                          type="text"
                          v-if="!tenantname_auto"
                        />
                        <p v-if="tenantname_auto">{{ tenantname }}</p>
                        <v-text-field
                          label="Benutzername"
                          name="username"
                          v-model="username"
                          prepend-icon="person"
                          type="text"
                        />
                        <v-text-field
                          label="Password"
                          name="password"
                          v-model="password"
                          prepend-icon="lock"
                          type="password"
                          v-on:keyup.enter="login"
                        />
                      </v-form>
                    </v-card-text>
                    <v-card-text v-if="errors.length > 0">
                      <v-alert
                        v-for="error in errors" :key="error.id"
                        border="top"
                        color="red lighten-2"
                        dark
                      >
                        {{ error.msg }} <v-btn icon small class="float-right" @click="removeError(error)"><v-icon>mdi-close-circle</v-icon></v-btn>
                      </v-alert>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="blue-grey" dark type="submit">Login</v-btn>
                    </v-card-actions>
                  </v-card>
                   </form>
                </v-col>
              </v-row>
             
          </v-layout>
        </v-container>
        
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      tenantname: null,
      tenantname_auto: false,
      username: null,
      password: null,
      hostname : null,
      hostnames : [
        { hostname : 'apps.stmw.cloud', tenantname : 'stegertmw' },
        { hostname : '10.0.24.34', tenantname : 'stegertmw' }
      ]
    }
  },
  computed: {
    ...mapState([
      'errors'
    ]),
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize() {
      this.hostname = location.hostname || null
      if(this.hostname){
        let tenantname = (this.hostnames.find(h => h.hostname == this.hostname) || {tenantname : null}).tenantname
        if(tenantname){
          this.tenantname = tenantname
          this.tenantname_auto = true
        }
      }
    },
    login () {
      const { tenantname, username, password } = this
      this.$store.dispatch('AUTH_REQUEST', { tenantname, username, password }).then(() => {
        this.$router.push('/')
      })
    },
    removeError(error){
      this.$store.dispatch('REMOVE_ERROR', error)
    }
  }

}
</script>

<style>
  .loginbackground {background-image: url('https://images.unsplash.com/photo-1517757910079-f57fd7f49a91?&w=2068');}
</style>
