<template>
    <div>
      <v-container fluid class="px-4 py-4 grey lighten-5">
      <v-row>
        <v-col cols="12" md="3"
          v-for="app in apps"
          :key="app._id"
        >
          <v-card
            @click="goto(app)"
          >
            <v-card-text class="text-center">
              <v-icon>{{ app.icon }}</v-icon><br/>
              <span>{{ app.name }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      </v-container>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
    }
  },
  computed: {
    ...mapState([
      'apps'
    ]),
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize(){ },
    goto(app){
      //window.open("http://apps.horizonx.cloud/" + route)
      window.open("/" + app.route)
    }
  },


}
</script>

<style lang='scss'>

</style>
