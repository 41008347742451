import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

import Login from './views/Login.vue'
import Home from './views/Home.vue'

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'home', component: Home, beforeEnter: ifAuthenticated},
    { path: '/login', name: 'login', component: Login },
  ]
})
