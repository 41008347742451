import Vue from 'vue'

import VueCookies from 'vue-cookies'
import VueMoment from 'vue-moment'
import axios from 'axios'

import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'

Vue.use(VueMoment)
Vue.use(VueCookies)
Vue.$cookies.config('7d')

//const API_KEY = 'ABCDEF'
//axios.defaults.headers.common['x-api-key'] = API_KEY
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const authToken = Vue.$cookies.get('x-access-token') || localStorage.getItem('x-access-token')

if (authToken) {
  axios.defaults.headers.common['x-access-token'] = authToken
  store.dispatch("AUTH_SETTOKEN", authToken)
  store.dispatch("AUTH_SETUSER")  
} else {
  console.log('no authToken found in cookies')
}


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
