<template>
    <div>
      <v-bottom-navigation
        color="teal"
        grow
        fixed
      >
        

        <!--
        <v-btn>
          <span>Favorites</span>

          <v-icon>mdi-heart</v-icon>
        </v-btn>

        <v-btn>
          <span>Nearby</span>

          <v-icon>mdi-map-marker</v-icon>
        </v-btn>
        -->
        <v-btn
          @click="setupNewTask()"
        >
          <span>Hinzufügen</span>
          <v-icon>add</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'customBottomBar',
  computed: {
    ...mapState([

    ])
  },
  methods: {
    ...mapActions([

    ])
  }

}
</script>

<style lang="scss">
.bottom-toolbar {
  position: fixed;
  bottom: 0px;
  z-index:2;
}
</style>
