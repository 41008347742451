import axios from 'axios'

const API_GATEWAY = 'api.horizonx.cloud/'
const IAM ='identity.horizonx.cloud/'
const SERVICE_APPS = 'apps/'
const DOMAIN = 'horizonx.cloud'

export default {

  AUTH_REQUEST: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit('AUTH_REQUEST')
      axios({ url: 'http://' + IAM + 'auth/login', data: user, method: 'POST' })
        .then(resp => {
          const authToken = resp.data.token
          localStorage.setItem('x-access-token', authToken)     
          window.$cookies.set('x-access-token',authToken,null,null, DOMAIN)
          window.$cookies.set('x-access-token',authToken,null,null, window.location.hostname)   
          axios.defaults.headers.common['x-access-token'] = authToken          
          commit('AUTH_SUCCESS', authToken)  
          dispatch('AUTH_SETUSER')   
          resolve(true)       
        })
        .catch(err => {
          commit('AUTH_ERROR', err)
          commit('ADD_ERROR', {code : null, category: 'login', msg:"Login fehlgeschlagen"})
          localStorage.removeItem('x-access-token')
          window.$cookies.remove('x-access-token',null, DOMAIN)
          window.$cookies.remove('x-access-token',null, window.location.hostname)
          reject(err)
        })
    })
  },
  AUTH_SETUSER: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {            
      axios({ url: 'http://' + IAM + 'auth/me', method: 'GET' })
        .then(resp => {
          const me = resp.data
          commit('AUTH_SETUSER', me)
          dispatch('A_INITIALIZE')
          resolve(resp)
        })
        .catch(err => {
          commit('AUTH_ERROR', err)
          localStorage.removeItem('x-access-token')
          window.$cookies.remove('x-access-token',null, DOMAIN)
          window.$cookies.remove('x-access-token',null, window.location.hostname)
          reject(err)
        })
    })
  },
  AUTH_SETTOKEN: ({ commit, dispatch }, token) => {
    commit('AUTH_SUCCESS', token)
  },
  A_INITIALIZE: ({ commit, dispatch }) => {
    dispatch('GET_APPS')
  },
  AUTH_LOGOUT: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit('AUTH_LOGOUT')
      localStorage.removeItem('x-access-token')
      window.$cookies.remove('x-access-token',null, DOMAIN)
      window.$cookies.remove('x-access-token',null, window.location.hostname)
      delete axios.defaults.headers.common['x-access-token']
      resolve()
    })
  },

  GET_APPS: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {            
      axios({ url: 'http://' + API_GATEWAY + SERVICE_APPS + 'apps', method: 'GET' })
        .then(resp => {
          const objs = resp.data
          commit('SET_OBJECTS', {objectarray: 'apps', objs: objs})
          resolve(true)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  REMOVE_ERROR: ({commit}, error) => {
    commit('REMOVE_ERROR', error)
  },



}
