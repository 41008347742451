<template>
    <v-footer color="grey" class="white--text">
        <span class="caption">&copy; {{ new Date().getFullYear() }}</span>
        <span class="caption ml-2">HorizonX | Skylight Technologies</span>
        <v-spacer />
        <v-btn text small class="caption white--text" to="/">Hilfe</v-btn>
        <v-btn text small class="caption white--text" to="/">Support</v-btn>        
    </v-footer>
</template>

<script>
export default {
  name: 'component-footer'
}
</script>
