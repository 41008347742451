import jwt from 'jsonwebtoken'
import { uniqueId } from 'lodash'

const secret = 'BEE2F917JVAZ62N9S6FM129AJVOANEM204JS764NDA'

export default {
  AUTH_REQUEST: (state) => {
    state.authStatus = 'loading'
  },
  AUTH_SUCCESS: (state, token) => {
    state.authStatus = 'success'
    state.authToken = token
    const decoded = jwt.verify(token, secret)
    state.authScopes = decoded.scopes
  },
  AUTH_SETUSER: (state,user) => {
    state.authUser = user
  } , 
  AUTH_ERROR: (state) => {
    state.authStatus = 'error'
  },
  AUTH_LOGOUT: (state) => {
    state.authToken = ''
    state.authStatus = ''
    state.authUser = null
  },

  SET_OBJECTS: (state, payload) => {
    state[payload.objectarray] = payload.objs
  },
  ADD_OBJECT: (state, payload) => {
    state[payload.objectarray].push(payload.obj)
  },
  UPDATE_OBJECT: (state, payload) => {    
    const item = state[payload.objectarray].find(item => item._id === payload.obj._id);
    Object.assign(item, payload.obj)
  },  
  DELETE_OBJECT: (state, payload) => {
    var idx = state[payload.objectarray].findIndex(o => {return o._id == payload.obj._id})
    state[payload.objectarray].splice(idx,1)
  },
  REFRESH_OBJECTS: (state, payload) => {
    let curObjs = state[payload.objectarray]
    curObjs.forEach(function(o, i, a) {
      let tempObj = payload.objs.find(obj => obj._id == o._id)
      if(tempObj) {
        a[i] = tempObj // update object
      } else {
        a.splice(i,1) // remove
      }
    })
    payload.objs.filter(o => !(curObjs.some(co => co._id == o._id)) ).forEach(o => {curObjs.push(o)})
    state[payload.objectarray] = curObjs
  },

  ADD_ERROR: (state, payload) => {
    state.errors.push({
      id : uniqueId(),
      category : payload.category,
      code : payload.code,
      msg : payload.msg,
    })
  },
  REMOVE_ERROR: (state, payload) => {
    let idx = state.errors.findIndex(e => e.id == payload.id)
    state.errors.splice(idx, 1)
  }

}
